import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 514.000000 290.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,290.000000) scale(0.100000,-0.100000)" >
				<path d="M975 2670 c-2 -5 179 -489 281 -752 l46 -118 180 0 181 0 -6 38 c-3
20 -9 70 -13 110 -6 69 -6 72 15 72 11 0 21 4 21 9 0 5 -48 134 -107 287 -58
154 -112 295 -119 314 l-12 35 -232 5 c-128 3 -233 3 -235 0z"/>
<path d="M3164 2671 c-2 -2 -4 -114 -4 -248 l0 -243 90 7 90 6 1 -34 c0 -19 2
-41 4 -50 1 -9 8 -74 15 -145 7 -71 17 -134 22 -141 19 -23 140 -35 318 -31
208 6 250 17 321 88 75 75 79 97 79 472 0 311 0 318 -20 318 -20 0 -20 -7 -20
-322 0 -291 -2 -327 -19 -363 -50 -111 -120 -145 -313 -153 l-128 -5 0 421 0
421 -216 3 c-119 2 -218 1 -220 -1z"/>
<path d="M1991 2648 c-76 -195 -137 -353 -151 -393 -10 -27 -32 -82 -49 -122
-34 -80 -38 -103 -16 -103 17 0 39 40 71 129 12 36 50 138 84 226 119 308 112
285 89 285 -11 0 -23 -10 -28 -22z"/>
<path d="M2072 2653 c3 -16 19 -18 146 -21 l142 -3 0 -264 0 -265 33 0 c17 0
113 9 211 20 99 11 183 20 188 20 4 0 8 110 8 245 l0 245 140 0 c133 0 140 1
140 20 0 20 -7 20 -506 20 -476 0 -505 -1 -502 -17z m559 -121 c-23 -39 -41
-75 -41 -80 0 -6 19 -13 43 -17 23 -4 47 -10 54 -14 7 -4 -40 -47 -118 -108
-71 -57 -129 -100 -129 -96 0 3 20 39 45 79 25 39 45 74 45 77 0 3 -25 11 -55
17 -30 6 -55 14 -54 18 0 4 41 39 91 77 51 39 106 82 122 97 17 14 33 25 34
23 2 -2 -15 -35 -37 -73z"/>
<path d="M3265 2154 c-11 -1 -90 -10 -175 -19 -278 -28 -632 -65 -770 -80 -74
-8 -250 -27 -390 -41 -140 -14 -256 -27 -257 -28 -2 -2 11 -131 27 -287 17
-156 37 -354 46 -439 9 -85 18 -157 20 -159 2 -2 245 22 541 53 296 31 648 67
783 81 135 14 260 28 279 32 l34 6 -6 71 c-3 39 -18 184 -32 321 -15 138 -32
304 -38 370 -13 125 -14 127 -62 119z m-127 -279 c158 -383 195 -476 191 -479
-2 -2 -41 -7 -86 -11 -57 -5 -83 -4 -83 3 0 6 -14 42 -31 81 l-32 70 -51 -5
c-28 -3 -56 -7 -62 -9 -6 -2 -19 -42 -29 -88 l-18 -86 -66 -6 c-36 -4 -73 -7
-81 -8 -10 -1 -14 6 -12 23 6 59 110 621 116 627 5 5 149 21 171 19 15 -1 31
-29 73 -131z m-535 78 c2 -5 15 -114 28 -244 19 -198 21 -243 11 -277 -42
-139 -249 -177 -344 -64 -24 29 -48 105 -48 152 0 21 11 25 98 35 l52 6 0 -31
c0 -47 16 -70 49 -70 56 0 57 10 32 251 -14 126 -20 224 -15 229 5 5 29 10 54
13 25 2 53 4 62 5 9 1 19 -1 21 -5z m-563 -85 c69 -35 85 -74 105 -256 22
-191 18 -240 -22 -290 -42 -52 -76 -66 -159 -67 -59 0 -76 4 -107 25 -41 28
-80 90 -75 122 3 22 13 25 77 28 55 3 61 1 61 -15 0 -19 48 -31 69 -18 11 7
17 28 19 74 1 37 -1 59 -6 51 -13 -20 -66 -30 -112 -22 -84 15 -129 73 -137
174 -9 101 22 161 100 200 48 23 134 21 187 -6z"/>
<path d="M4200 1270 c-10 -10 -30 -41 -44 -67 -13 -26 -43 -79 -65 -118 -33
-56 -41 -79 -41 -119 l0 -48 78 7 c42 3 87 9 100 12 18 4 22 1 22 -17 0 -44
15 -57 62 -51 43 6 43 7 40 43 -3 32 0 37 20 40 13 2 23 9 22 16 -1 6 -2 26
-2 42 -2 25 -6 30 -26 30 -26 0 -28 6 -41 135 -11 116 -11 115 -61 115 -30 0
-50 -6 -64 -20z m41 -190 l4 -55 -42 -3 c-24 -2 -43 1 -43 7 0 5 14 37 32 70
36 68 44 65 49 -19z"/>
<path d="M3785 1243 c-65 -7 -121 -15 -122 -17 -4 -3 15 -187 23 -223 4 -20
11 -23 48 -23 33 0 47 5 58 21 16 24 59 29 83 9 20 -16 19 -74 -1 -94 -20 -21
-46 -20 -75 2 -17 14 -36 17 -66 14 -47 -5 -51 -11 -27 -52 25 -41 65 -60 126
-60 123 0 187 92 144 209 -24 66 -104 98 -166 67 -39 -19 -50 -14 -50 24 0 32
5 34 118 45 55 6 62 9 62 28 0 36 -12 67 -24 66 -6 -1 -65 -8 -131 -16z"/>
<path d="M3373 1190 c-38 -23 -63 -67 -63 -111 0 -32 0 -32 42 -25 23 4 42 8
43 9 0 1 7 15 15 32 29 56 80 40 80 -24 0 -44 -34 -100 -109 -181 -45 -48 -50
-59 -45 -85 4 -16 8 -32 10 -34 4 -4 228 18 262 26 17 4 22 12 22 38 0 19 -4
36 -9 39 -5 3 -38 2 -73 -3 -34 -5 -65 -7 -67 -5 -2 2 14 25 36 51 58 69 73
104 73 169 0 50 -3 59 -34 90 -31 31 -39 34 -92 34 -41 0 -69 -6 -91 -20z"/>
<path d="M3009 1147 c-49 -33 -69 -79 -69 -162 0 -85 13 -138 47 -187 32 -47
68 -61 143 -56 105 8 147 73 138 215 -6 88 -45 176 -88 198 -45 23 -130 19
-171 -8z m123 -79 c24 -19 38 -74 38 -144 0 -57 -3 -65 -25 -80 -36 -24 -73
-11 -91 31 -20 49 -19 163 3 187 19 21 52 23 75 6z"/>
<path d="M2654 1120 c-81 -32 -104 -200 -48 -341 28 -69 95 -99 175 -79 78 20
109 59 109 137 0 60 -16 91 -59 120 -42 28 -75 29 -126 3 -22 -11 -41 -20 -43
-20 -9 0 7 81 18 95 16 20 69 19 76 0 4 -11 21 -15 55 -15 27 0 49 3 49 6 0
21 -35 73 -59 87 -31 18 -111 22 -147 7z m126 -240 c39 -39 14 -100 -41 -100
-57 0 -78 89 -26 110 34 13 45 12 67 -10z"/>
<path d="M2336 1083 c-26 -4 -28 -7 -22 -35 3 -17 6 -37 6 -44 0 -9 11 -14 29
-14 33 0 32 2 53 -204 l12 -128 49 6 c28 3 51 7 53 9 2 2 -6 97 -18 210 l-23
207 -55 -1 c-30 -1 -68 -4 -84 -6z"/>
<path d="M2095 1063 c-87 -18 -133 -71 -121 -136 4 -19 17 -46 30 -58 l24 -23
-21 -28 c-25 -33 -28 -93 -8 -131 22 -42 85 -70 144 -65 68 6 106 25 135 68
34 50 26 113 -18 153 -31 28 -31 29 -13 49 27 30 24 100 -6 132 -34 36 -91 51
-146 39z m72 -111 c8 -35 -8 -56 -45 -60 -26 -3 -34 2 -43 22 -14 31 -6 54 23
65 33 12 57 2 65 -27z m22 -156 c20 -24 9 -69 -21 -86 -25 -13 -31 -13 -55 0
-31 17 -42 62 -22 86 6 8 28 14 49 14 21 0 43 -6 49 -14z"/>
<path d="M1631 980 c-91 -42 -133 -129 -112 -232 33 -158 217 -221 337 -116
35 31 64 76 64 100 0 9 -14 12 -50 10 -39 -3 -54 -9 -66 -26 -35 -55 -102 -60
-150 -12 -30 30 -34 40 -34 89 0 69 26 105 83 114 30 4 45 1 67 -16 31 -23
113 -30 125 -10 11 18 -38 78 -81 99 -52 26 -126 25 -183 0z"/>
<path d="M1195 943 c-27 -2 -54 -8 -58 -12 -9 -9 31 -392 41 -402 4 -3 27 -4
51 -1 l43 4 -7 80 c-8 98 0 99 65 7 43 -60 49 -64 89 -67 73 -5 76 1 30 68
-22 33 -45 62 -50 66 -16 10 -9 30 15 43 45 25 61 104 31 160 -28 55 -82 66
-250 54z m149 -89 c20 -19 21 -58 1 -74 -8 -6 -32 -14 -53 -17 -37 -5 -39 -4
-46 27 -10 55 -7 68 17 73 45 9 66 7 81 -9z"/>

</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
